import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { TokenService } from "app/token.service";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _authService: TokenService, private _router: Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check("/");
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
    return this._check(redirectUrl);
  }
  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === "/sign-out" ? "/" : state.url;
    return this._check(redirectUrl);
  }

  private _check(redirectURL: string): Observable<boolean> {
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        console.log(authenticated);
        // If the user is not authenticated...
        if (authenticated) {
          setTimeout(() => {
            this.routeToService();
          }, 800);
        }

        // Allow the access
        return of(true);
      })
    );
  }

  routeToService() {
    if (localStorage.getItem("token") !== "null") {
      const data = JSON.parse(localStorage.getItem("token"));
      switch (data.service?.code) {
        case "SL":
          location.replace(environment.ShippingLine);
          return of(false);
        case "SA":
          location.replace(environment.ShippingAgent);
          return of(false);
        case "SC":
          location.replace(environment.Shipper);
          return of(false);
        case "TR":
          location.replace(environment.Trucking);
          return of(false);
        case "CB":
          location.replace(environment.CustomsBroker);
          return of(false);
        case "FF":
          location.replace(environment.FreightForwarder);
          return of(false);
        default:
          return of(false);
      }
    }
  }
}
