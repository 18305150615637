import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor() { }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
