import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';


@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class PopUpComponent implements OnInit {
  data: any;
  constructor(
    public matDialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public router: Router,
  ) {
    this.data = _data;
  }

  ngOnInit(): void {
  }

  goToForgot(){
    this.matDialogRef.close()
    this.router.navigate(['/forgot-password']);

  }

}
