<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
  <div
    id="pop-up-wrapper"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="pop-up-page">
      <div class="s-100" [class]="data.title">
        <img [src]="icon" class="mb-8" alt="" />
        <div class="title border-bottom pb-24">
          {{ data.type }}
        </div>
      </div>

      <div class="description">
        <div [innerHTML]="data?.description"></div>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.button"
        fxLayoutGap="10px"
      >
        <button
          mat-button
          class="grey-600"
          *ngIf="data.button.closeTitle"
          (click)="action(data?.button?.closeTitle)"
        >
          {{ data?.button?.closeTitle }}
        </button>
        <button
          *ngIf="data?.button?.actionTitle"
          mat-button
          class="accent"
          (click)="action(data?.button?.actionTitle)"
        >
          {{ data?.button?.actionTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
