// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// export const environment = {

//     globalCMSAggregator: 'https://global-cms-api.dev.xlog.asia',
//     authManagementAuthentication: 'https://auth-service.dev.xlog.asia/auth',
//     transactionManagement: "https://transaction-service.dev.xlog.asia",

//     // Pointer of Local Select Login

//     ShippingLine: 'http://localhost:4201/main',
//     ShippingAgent: 'http://localhost:4202/main',
//     Shipper: 'http://localhost:4203/main',
//     Trucking: 'http://localhost:4204/main',
//     FreightForwarder: 'http://localhost:4205/main',
//     CustomsBroker: 'http://localhost:4206/main',
//     // Pointer in Key

//     googleMapsApiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
//     captchaKey: '6Lfa3qIZAAAAAI4M-7c3hFap3L-ZSuUbJ8XxGZWI',

//     ssoUrl: 'https://sso-middleware.dev.xlog.asia',

//     production: false,
//     hmr: false
// };

export const environment = {
  globalCMSAggregator: "https://global-cms-api.develop.xlog.asia",
  authManagementAuthentication:
    "https://auth-service-api.develop.xlog.asia/auth",
  transactionManagement: "https://transaction-service-api.develop.xlog.asia",

  // Pointer of Local Select Login
  ShippingLine: "https://shipping-line.develop.xlog.asia/main",
  Shipper: "https://shipper-consignee.develop.xlog.asia/main",
  ShippingAgent: "https://shipping-agent.develop.xlog.asia/main",
  Trucking: "https://trucking.develop.xlog.asia/main",
  FreightForwarder: "https://freight-forwarder.develop.xlog.asia/main",
  CustomsBroker: "https://custom-broker.develop.xlog.asia/main",

  // Pointer in Key
  googleMapsApiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
  captchaKey: "331b0293-caec-454f-b001-cf23c000a3cd",
  ssoUrl: "https://sso-middleware-api.develop.xlog.asia",

  production: true,
  hmr: false,
};

// for web auth in cloud 9
// captchaKey: "6LeDv8AZAAAAADE88-y9IztKgd95UKJD2pV0Ze2L",
