import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { DataService } from "./services/data.service";
import { catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private _services: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _token: boolean = false;

  private frame = document.getElementById("frame");
  private iframe;

  constructor(private dataService: DataService) {
    this.iframe = document.createElement("iframe");
    this.iframe.setAttribute("id", "middleware-iframe");
    this.iframe.style.display = "none";
    this.iframe.src = environment.ssoUrl;
    this.frame.appendChild(this.iframe);
  }

  set _servicesData(token) {
    this._services = token;
  }

  get _servicesActor() {
    return this._services.asObservable();
  }
  private refreshTokenSubject = new Subject<boolean>();
  getTokenOnInit() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const { action, key, value } = event.data;

        if (action === "returnData") {
          localStorage.setItem(key, JSON.stringify(value));
        }
        resolve("");
      };

      this.iframe.onload = () => {
        window.addEventListener("message", messageHandler, false);
        this.iframe.contentWindow.postMessage(
          {
            action: "get",
            key: "token",
          },
          "*"
        );
      };
    });
  }

  setToken(data) {
    return new Promise<void>((resolve, reject) => {
      this.iframe.contentWindow.postMessage(
        {
          action: "save",
          key: "token",
          value: data,
        },
        "*"
      );
      resolve();
    });
  }

  getToken() {
    return new Promise<any>((resolve, reject) => {
      const messageHandler = (event) => {
        const { action, key, value } = event.data;

        if (action === "returnData") {
          localStorage.setItem(key, JSON.stringify(value));
        }
        resolve("");
      };

      window.addEventListener("message", messageHandler, false);
      this.iframe.contentWindow.postMessage(
        {
          action: "get",
          key: "token",
        },
        "*"
      );
    });
  }

  check(): Observable<boolean> {
    let data = this.getTokenOnInit();

    if (
      localStorage.getItem("token") == "{}" ||
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == "null"
    ) {
      return of(false);
    }
    if (localStorage.getItem("token") ?? "") {
      return this.callRefreshToken().pipe(
        switchMap(() => {
          return of(true);
        }),
        catchError((error) => {
          // Handle token refresh error
          return of(false);
        })
      );
    }

    return of(false);
  }

  callRefreshToken(): Observable<any> {
    return this.dataService.generateNewToken().pipe(
      switchMap((res: any) => {
        const data = JSON.parse(localStorage.getItem("token"));
        let { authenticationResult, challengeName, challengeParameters } =
          res && res.data;
        let newToken = {
          ...data,
          cognito: {
            authenticationResult: {
              ...data.cognito.authenticationResult,
              authenticationResult,
            },
            challengeName,
            challengeParameters,
            clientMetadata: {},
            sessionId: null,
          },
        };
        this.setToken({
          cognito: newToken.cognito,
          service: newToken.service,
          username: newToken.username,
        });

        this._token = true;
        this.refreshTokenSubject.next(true);

        return of(true);
      }),
      catchError((err) => {
        this._token = false;
        this.refreshTokenSubject.next(false);
        return throwError(err);
      })
    );
  }
}
