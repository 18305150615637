import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { observable, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private httpClient: HttpClient) {}

  getInitialInfo(): any {
    return this.httpClient.get(
      `${environment.globalCMSAggregator}/global/cms/api/v1/website/initialInfo`
    );
  }

  getCountry(name = ""): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.globalCMSAggregator}/global/cms/api/v1/country/filter?countryName=${name}`
    );
  }

  getStates(countryId, name = ""): any {
    return this.httpClient.get(
      `${environment.globalCMSAggregator}/global/cms/api/v1/country/${countryId}/states?stateName=${name}`
    );
  }
  // /global/cms/api/v1/state/923f7e27-99f2-45f5-a120-6505f59ceb0f/cities?cityName=Kuching

  getCities(stateId, name = ""): any {
    return this.httpClient.get(
      `${environment.globalCMSAggregator}/global/cms/api/v1/state/${stateId}/cities?cityName=${name}`
    );
  }

  getPostal(id, name = ""): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.globalCMSAggregator}/global/cms/api/v1/postal/${id}/postal-codes?postalCode=${name}`
    );
  }

  getServices(): any {
    return this.httpClient.get(
      `${environment.globalCMSAggregator}/global/cms/api/v1/service`
    );
  }

  login(body): any {
    return this.httpClient.post(
      `${environment.authManagementAuthentication}/api/v1/auth/login`,
      body
    );
  }

  registration(body): any {
    return this.httpClient.post(
      `${environment.authManagementAuthentication}/api/v1/auth`,
      body
    );
  }

  emailPin(guid): any {
    return this.httpClient.put(
      `${environment.authManagementAuthentication}/api/v1/auth/generate-pin/${guid}`,
      ""
    );
  }

  userVerification(body, guid): any {
    return this.httpClient.patch(
      `${environment.authManagementAuthentication}/api/v1/authverification/${guid}`,
      body
    );
  }

  forgotPassword(body): any {
    return this.httpClient.post(
      `${environment.authManagementAuthentication}/api/v1/auth/forgot-password`,
      body
    );
  }

  changePasswordPin(body, guid): any {
    return this.httpClient.patch(
      `${environment.authManagementAuthentication}/api/v1/authverification/reset-password/${guid}`,
      body
    );
  }

  resendEmail(body): Observable<any> {
    return this.httpClient.post(
      `${environment.authManagementAuthentication}/api/v1/EmailResend/resend-account-confirmation-email`,
      body
    );
  }

  s3UploadImage(body): any {
    return this.httpClient.post(
      `${environment.globalCMSAggregator}/global/cms/api/v1/subMenuContent/upload`,
      body
    );
  }

  singleUpload(body): any {
    return this.httpClient.post(
      `${environment.globalCMSAggregator}/global/cms/api/v1/file/upload`,
      body
    );
  }

  getTransactionImage(payload): Observable<any> {
    return this.httpClient.get(
      `${environment.transactionManagement}/otm/api/v1/transactions/file/generate-presigned-url?path=${payload}`
    );
  }

  getClientImage(payload): Observable<any> {
    return this.httpClient.get(
      `${environment.globalCMSAggregator}/global/cms/api/v1/file/pre-signed?path=${payload}`
    );
  }

  generateNewToken(): Observable<any> {
    let data = JSON.parse(localStorage.getItem("token"));
    let {
      cognito: {
        authenticationResult: { refreshToken },
      },
    } = data;

    return this.httpClient.post(
      `${environment.authManagementAuthentication}/api/v1/auth/refresh/${data.username}`,
      { refreshToken }
    );
  }
}
