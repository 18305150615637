import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AppInitService } from './services/app-init.service';
import { HttpInterceptorService } from './interceptors/http-interceptors';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { PopUpModule } from './layout/components/pop-up/pop-up.module';
import { NotificationComponent } from './layout/components/notification/notification.component';
import { ProtectedImagePipe } from './core/pipe/protected-image/protected-image/protected-image.pipe';
import { ProtectedImageModule } from './core/pipe/protected-image/protected-image/protected-image.module';
import { SafePipe } from './core/pipe/protected-image/protected-image/safe.pipe';
import { AuthGuard } from './core/auth/auth.guard';


export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.init();
    }
}

const appRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        path: '',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        path: 'sample',
        loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule)
    },

];

@NgModule({
    declarations: [
        AppComponent,
        NotificationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        PopUpModule,
        SampleModule,
        ProtectedImageModule,

        MatPasswordStrengthModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
