import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import * as camelcase from 'camelcase-keys';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  currentRequestCount = 0;


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // start the loading spinner here
    this.currentRequestCount++;

    let token = localStorage.getItem('token') != null ?
      localStorage.getItem('token') : 'up-down-left-right-a-b-select-start';
    const authReq = req.clone({ headers: req.headers.set('Authorization', token) });
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // check connection errors
        if (error.status == 0) {
          return throwError
            ("Sorry, there seems to be a problem.." + "<br>" + "<br>" + "Refresh this page and try again.");
        }
        //return if status code is 500
        if (error.status === 500) {
          return throwError('Something went wrong!');
        }
        // handle http errors
        return throwError(
          error.error);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // transform all json response to camelCase
          const modEvent = event.clone({ body: camelcase(event.body, { deep: true }) });
          return modEvent;
        }
      }),
      finalize(() => {
        this.currentRequestCount--;
        if (this.currentRequestCount === 0) {
          // hide loading spinner if all requests are done
        }
      })
    );
  }

}
