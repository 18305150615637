import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class NotificationComponent implements OnInit {
  data: any;
  icon: any
  constructor(
    public dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.data = _data;
    this.icon = `assets/icons/xlog-icon/${this.data.title}-alert.svg`
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  action(data) {
    this.dialogRef.close(data)
  }
}
