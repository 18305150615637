<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
    <div id="pop-up-wrapper" fusePerfectScrollbar
        [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
        <div id="pop-up-page">
           
            <div *ngIf="data.data === 'success'"  class="s-100" style="color:#47C399;">
                <img src="assets/images/svg/check-alert.svg" class="mb-8" alt="">
                <div class="title border-bottom pb-24">
                    {{data.title}}
                 </div>
            </div>
            <div *ngIf="data.data === 'error'"  class="s-100" style="color:red;">
                <img src="assets/images/svg/error-alert.svg" class="mb-8" alt="">
                <div class="title border-bottom pb-24">
                    {{data.title}}
                 </div>
            </div>
            <div  *ngIf="data.data === 'warning'"  class="s-100" style="color:orange;">
                <img src="assets/images/svg/warning-alert.svg" class="mb-8" alt="">
                <div class="title border-bottom pb-24">
                    {{data.title}}
                 </div>
            </div>
            
            <div class="description">
                <div [innerHTML]="data?.description"></div>
            </div>
            <button *ngIf="!data.tryAgain && !data?.noToken" mat-flat-button class="submit-button" color="accent"
            (click)="matDialogRef.close()">
                CLOSE
            </button>
            <button *ngIf="!data.tryAgain && data?.noToken" mat-flat-button class="submit-button" color="accent"
            (click)="goToForgot()">
                Close
            </button>

            <div class="mt-8" fxLayout="row" fxLayoutAlign="center space-around" *ngIf="data.tryAgain">
                <button
                    mat-flat-button
                    class="grey-600 mr-8"
                    (click)="matDialogRef.close([''])"
                >
                <mat-icon>clear</mat-icon>
                    Cancel
                </button>
                <button mat-flat-button class="submit-button" color="accent" (click)="matDialogRef.close(['tryAgain'])">
                    <mat-icon class="s-24">refresh</mat-icon> Try Again
                </button>
            </div>
        </div>
    </div>
</div>